import React from "react";
import { string, shape, arrayOf, bool } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../Base/HeadingBuilder";
import CtaPrimary from "../../Base/CtaPrimary";
import AppetizeImage from "../../../components/ImageQuerys/LargeFeatures/EnterpriseOverviewImages";
import background from "../../../images/svg/vertical-half-circle-left.svg";
import backgroundRight from "../../../images/svg/vector-07.svg";
import checkmark from "../../../images/svg/checkmark.svg";
import useStartMotion from "../../../hooks/use-start-motion";

const slideBottomVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const slideLeftVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const slideRightVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const ProductMainPageOverview = ({
  className,
  title,
  subtext,
  ctaPrimary,
  subSections,
  offsetText,
  rightBackground,
  noMainTitle,
  flipItems,
  animations,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      data-cy="product-overview"
      className={`
      relative w-full
      ${className}
    `}
      ref={ref}
    >
      {!rightBackground && (
        <img
          className="absolute left-0 top-96 lg:top-40 transform rotate-180 z-0"
          src={background}
          alt="background"
        />
      )}
      {rightBackground && (
        <motion.img
          className="absolute right-0 bottom-60 z-0"
          src={backgroundRight}
          alt="background"
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={slideRightVariants}
          transition={{ delay: 2.5, duration: 0.8 }}
        />
      )}
      {/* TOP */}
      {!noMainTitle && (
        <div
          className={`
        grid max-w-1440 w-full z-1 relative
        my-0 mx-auto px-4 lg:px-10 xl:px-40
        grid-cols-1 lg:grid-cols-2 lg:gap-x-20 xl:gap-x-56
        mb-10 lg:mb-36
      `}
        >
          <motion.div
            animate={controls}
            initial={isMobileOnly || !animations ? "visible" : "hidden"}
            variants={slideBottomVariants}
            transition={{ delay: 0.7, duration: 0.8 }}
          >
            <Heading level={2} injectionType={1} withBlueSymbol>
              {title}
            </Heading>
          </motion.div>

          <motion.div
            className="flex flex-col justify-center lg:pb-20"
            animate={controls}
            initial={isMobileOnly || !animations ? "visible" : "hidden"}
            variants={slideBottomVariants}
            transition={{ delay: 0.1, duration: 0.8 }}
          >
            <p className="text-p text-black-300 mb-8 lg:mb-10">{subtext}</p>
            <CtaPrimary title={ctaPrimary.title} target={ctaPrimary.target} />
          </motion.div>
        </div>
      )}

      {subSections.map((section, idx) => {
        function createMarkup() {
          return { __html: section.title };
        }

        return (
          <div
            className={`
            relative z-1
            grid max-w-1440 w-full z-1 relative
            ${
              offsetText && idx > 0
                ? "mt-24 lg:mt-36"
                : idx > 0
                ? "mt-20 lg:mt-28"
                : ""
            }
            mx-auto px-4 lg:px-10 xl:px-40
            grid-cols-1 lg:grid-cols-2 lg:gap-x-12 xl:gap-x-32
          `}
            key={section.title}
            id={section.id || null}
          >
            <motion.div
              className={`
              relative z-1
              max-w-80-percent lg:max-w-100-percent
              ${
                idx % 2 === 0 && !flipItems
                  ? "lg:order-1"
                  : idx % 2 === 0 && flipItems
                  ? "lg:order-2"
                  : idx % 2 > 0 && !flipItems
                  ? "lg:order-2"
                  : "order-1"
              }`}
              animate={controls}
              initial={isMobileOnly || !animations ? "visible" : "hidden"}
              variants={slideLeftVariants}
              transition={{ delay: idx * 0.5, duration: 0.8 }}
            >
              <AppetizeImage imageName={section.imageName} />
            </motion.div>

            <motion.div
              className={`
                relative z-1
                flex flex-col justify-center lg:pb-20 mt-10
                lg:${offsetText ? "-mt-20" : "mt-0"}
                ${
                  idx % 2 === 0 && !flipItems
                    ? "lg:order-2"
                    : idx % 2 === 0 && flipItems
                    ? "lg:order-1"
                    : idx % 2 > 0 && !flipItems
                    ? "lg:order-1"
                    : "order-2"
                }
              `}
              animate={controls}
              initial={isMobileOnly || !animations ? "visible" : "hidden"}
              variants={slideRightVariants}
              transition={{ delay: (idx + 1) * 0.7, duration: 0.8 }}
            >
              <Heading level={3} injectionType={2} className="" withBlueSymbol>
                {/* {section.title} */}
                <span dangerouslySetInnerHTML={createMarkup()} />
              </Heading>

              {!section.bullets && (
                <>
                  <p
                    className="text-p text-black-300 mb-8 lg:mb-10"
                    dangerouslySetInnerHTML={{ __html: section.subtext }}
                  />
                  {section.cta && (
                    <CtaPrimary
                      title={section.cta.title}
                      target={section.cta.target}
                    />
                  )}
                  {section.bottomText && (
                    <div className="text-p text-black mt-6 font-semibold">
                      {section.bottomText}
                    </div>
                  )}
                </>
              )}

              {section.bullets &&
                section.bullets.map((bullet) => (
                  <div className="mb-6 flex flex-row">
                    <img src={checkmark} alt="checkmark" className="mr-4" />
                    <div className="text-h4-small font-bold text-black">
                      {bullet}
                    </div>
                  </div>
                ))}
            </motion.div>
          </div>
        );
      })}
    </section>
  );
};

ProductMainPageOverview.propTypes = {
  className: string,
  title: string,
  subtext: string,
  ctaPrimary: shape({
    title: string,
    target: string,
  }),
  subSections: arrayOf(
    shape({
      title: string,
      subtext: string,
      bullets: bool,
      imageName: string,
      cta: shape({
        title: string,
        target: string,
      }),
      bottomText: string,
      id: string,
    })
  ),
  offsetText: bool,
  rightBackground: bool,
  noMainTitle: bool,
  flipItems: bool,
  animations: bool,
};

ProductMainPageOverview.defaultProps = {
  className: "",
  title: "",
  subtext: "",
  ctaPrimary: null,
  subSections: [],
  offsetText: false,
  rightBackground: false,
  noMainTitle: false,
  flipItems: false,
  animations: true,
};

export default ProductMainPageOverview;
